<template>
  <div class="DeviceConfigForm-container">
    <tabs-panel :tabs="tabs" :tabRouter="false" :before="getDetail" :activeLabel.sync="activeLabel"></tabs-panel>
  </div>
</template>
<script>
import { TabsPanel } from 'components/bussiness'
import baseInfo from './templateForm/BaseInfo'
import paramsInfo from './templateForm/ParamsInfo'
import maintenanceInfo from './templateForm/MaintenanceInfo'
import inspectionInfo from './templateForm/InspectionInfo'
import { getDetailURL } from './api'
export default {
  components: {
    TabsPanel
  },
  data () {
    return {
      blueTooth: {},
      tabs: [],
      public: {
        id: '',
        area: '',
        communityId: '',
        categoryId: ''
      },
      response: {
        data: null
      },
      activeLabel: '基础信息'
    }
  },
  created () {
    this.tabs = [
      {
        label: '基础信息',
        component: baseInfo,
        props: {
          publicParams: this.public,
          responseData: this.response
        }
      }, {
        label: '参数信息',
        component: paramsInfo,
        props: {
          publicParams: this.public,
          responseData: this.response
        }
      }, {
        label: '维保信息',
        component: maintenanceInfo,
        props: {
          publicParams: this.public,
          responseData: this.response
        }
      }, {
        label: '巡检信息',
        component: inspectionInfo,
        props: {
          publicParams: this.public,
          responseData: this.response
        }
      }
    ]
  },
  methods: {
    setActiveLabel (activeLabel) {
      this.activeLabel = activeLabel
    },
    getDetail (next) {
      if (this.$route.query.id) {
        this.public.id = this.$route.query.id
        this.$axios.get(`${getDetailURL}?id=${this.$route.query.id}`).then(res => {
          if (res.status === '100') {
            let { data } = res
            this.response.data = data
            this.public.maintContent = data.maintContent
            this.public.commmonMeasureContent = data.commmonMeasureContent
            this.public.commmonPatrolContent = data.commmonPatrolContent
            next()
          }
        })
      } else {
        next()
      }
    }
  }
}
</script>
