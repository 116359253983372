<template>
  <div>
    <form-panel class="form-container"
                ref="formPanel"
                :form="form"
                v-bind="submitConfig"
                :submitUrl="submitUrl"
                submitText="保存"
                :submitBefore="submitBefore"
                :submitSuccess="submitSuccess">
      <el-form-item label="是否巡检"
                    prop="isPatrol"
                    :rules="[{ required: true, message: '请输入是否巡检', trigger: 'change' }]">
        <v-select v-model="form.isPatrol"
                  :options="maintenanceOps"></v-select>
      </el-form-item>
      <div v-if="form.isPatrol !== 0">
        <el-form-item label="巡检负责人"
                      prop="patrolChargerUserId"
                      :rules="[{ required: true, message: '请输入巡检负责人', trigger: 'change' }]">
          <v-select2 v-model="form.patrolChargerUserId"
                     placeholder="巡检负责人"
                     :subjoin="maintCompanyUserExtra"
                     v-bind="maintChargerUserParams"></v-select2>
        </el-form-item>
        <!-- <el-form-item
          label="巡检方式"
          prop="type"
          :rules="[{ required: true, message: '请输入巡检方式', trigger: 'change' }]"
        >
          <v-select v-model="form.type" :options="maintenanceTypeOps"></v-select>
        </el-form-item>
        <el-form-item
          v-if="form.type === 1"
          label="蓝牙编号"
          prop="bluetoothId"
          :rules="[{ required: true, message: '请填写蓝牙编号', trigger: 'blur'}]"
        >
          <v-select2 v-model="form.bluetoothId" placeholder="选择蓝牙" :subjoin="bluetoothExtra" v-bind="bluetoothParams"></v-select2>
        </el-form-item> -->
        <el-form-item label="巡检频次"
                      required>
          <div class="content-wrapper">
            <el-form-item prop="period"
                          :rules="{ required: true, message: '当前选项不允许为空', trigger: 'change' }">
              <v-select class="right-wrapper"
                        v-model="form.period"
                        :options="periodFormOps"
                        :width="182"></v-select>
            </el-form-item>
            <el-form-item prop="periodNum"
                          :rules="{ required: true, message: '内容不允许为空', trigger: 'blur' }">
              <v-input-number controls
                              v-model="form.periodNum"
                              controls-position="right"
                              placeholder="1"
                              :min="0"
                              :max="99"
                              :width="182"></v-input-number>
            </el-form-item>
            <span class="right-tip">如：“日、3”表示每日巡检3次</span>
          </div>
        </el-form-item>
        <el-form-item label="巡检间隔"
                      required>
          <div class="content-wrapper">
            <el-form-item prop="timespan"
                          :rules="{ required: true, message: '内容不允许为空', trigger: 'blur' }">
              <v-input-number controls
                              v-model="form.timespan"
                              class="right-wrapper"
                              controls-position="right"
                              placeholder="1"
                              :min="0"
                              :max="9999999999"
                              :width="182"></v-input-number>
            </el-form-item>
            <el-form-item prop="timespanUnit"
                          :rules="{ required: true, message: '当前选项不允许为空', trigger: 'change' }">
              <v-select v-model="form.timespanUnit"
                        :options="timespanUnitOps"
                        :width="182"></v-select>
            </el-form-item>
            <span class="right-tip">两次巡检间隔最小时间</span>
          </div>
        </el-form-item>
        <el-form-item label="提醒时间"
                      prop="floatingDays"
                      :rules="[{ required: true, message: '请输入维保提醒', trigger: 'change' }]">
          <v-select v-model="form.floatingDays"
                    :options="floatingDaysOps"></v-select>
        </el-form-item>
        <el-form-item label="首次巡检日期"
                      prop="firstPatrolDate"
                      :rules="[{ required: true, message: '请输入首次巡检日期', trigger: 'change' }]">
          <div class="content-wrapper-allowAlbumUpload">
            <v-datepicker v-model="form.firstPatrolDate" type="date" />
            <div class="right-tip">
              <el-form-item label="是否允许相册上传图片" prop="allowAlbumUpload">
                <el-switch v-model="form.allowAlbumUpload" :active-value="1" :inactive-value="0" active-color="#409EFF"/>
              </el-form-item>               
            </div>
          </div>
        </el-form-item>
        <el-form-item label="巡检内容">
          <div>
            <div v-for="(item, indexp) in commmonPatrolContent"
                 :key="indexp">{{indexp+1}}.{{item}}</div>
          </div>
          <div class="content"
               v-for="(item, index) in form.patrolContent"
               :key="index">
            <v-input v-model="item.content"
                     :width="385" :maxlength="300" />
            <v-button v-if="form.patrolContent.length > 1"
                      type="danger"
                      text="删除"
                      @click="removePatrolContent(index)"></v-button>
            <v-button v-if="index === (form.patrolContent.length - 1)"
                      text="添加巡检内容"
                      @click="addPatrolContent"></v-button>
          </div>

        </el-form-item>
        <el-form-item label="是否抄表"
                      prop="needMeasureDevice"
                      :rules="[{ required: true, message: '请输入是否抄表', trigger: 'change' }]">
          <v-select v-model="form.needMeasureDevice"
                    :options="maintenanceOps"></v-select>
        </el-form-item>
        <el-form-item v-if="form.needMeasureDevice === 1"
                      label="抄表内容 ">
          <div>
            <div v-for="(item, indexp) in commmonMeasureContent"
                 :key="indexp">{{indexp+1}}.{{item}}</div>
          </div>
          <div class="content"
               v-for="(item, index) in form.measureContent"
               :key="index">
            <v-input v-model="item.content"
                     placeholder="抄表内容"
                     :width="385"
                     :maxlength="300" />
            <v-input v-model="item.unit"
                     placeholder="单位" />
            <v-button v-if="form.measureContent.length > 1"
                      type="danger"
                      text="删除"
                      @click="removeMeasureContent(index)"></v-button>
            <v-button v-if="index === (form.measureContent.length - 1)"
                      text="添加抄表内容"
                      @click="addMeasureContent"></v-button>
          </div>
        </el-form-item>
      </div>
    </form-panel>
  </div>
</template>
<script>
import { saveDevicePatrolURL, getBlueteethURL, getUsernameURL, getPeriodDetailURL } from './../api'
import { maintenanceOps, maintenanceTypeOps, patrolPeriodOps, timespanUnitOps, periodFormOps } from './../map'
export default {
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      maintenanceOps,
      maintenanceTypeOps,
      patrolPeriodOps,
      timespanUnitOps: timespanUnitOps(),
      periodFormOps: periodFormOps(),
      submitUrl: saveDevicePatrolURL,
      commmonPatrolContent: [],
      commmonMeasureContent: [],
      submitConfig: {
        submitContentType: 'application/json;charset=UTF-8'
      },
      changeCount:0,
      form: {
        isPatrol: 1,
        // type: undefined,
        // bluetoothId: '',
        // 频次类型
        period: 0,
        // 频次
        periodNum: undefined,
        // 间隔时间
        timespan: undefined,
        // 间隔类型 1小时/2日
        timespanUnit: 1,
        floatingDays: 0,
        firstPatrolDate: '',
        needMeasureDevice: 0,
        patrolContent: [{ content: '' }],
        measureContent: [{ content: '', unit: '' }],
        allowAlbumUpload: 1
      },
      bluetoothParams: {
        searchUrl: getBlueteethURL,
        request: {
          text: 'serialNum',
          value: 'id'
        },
        response: {
          text: 'serialNum',
          value: 'id'
        }
      },
      maintChargerUserParams: {
        searchUrl: getUsernameURL,
        request: {
          text: 'username',
          value: 'userId'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      }
    }
  },
  activated () {
    this.$nextTick(() => {
      this.commmonPatrolContent = this.publicParams.commmonPatrolContent
      this.commmonMeasureContent = this.publicParams.commmonMeasureContent

    })
  },
  computed: {
    bluetoothExtra () {
      let { communityId, area } = this.publicParams
      return { communityId, area }
    },
    floatingDaysOps () {
      let ops = []
      if (this.form.period === 0) {
        // 日
        ops = [{ text: '一天', value: 0 }]
      } else if (this.form.period === 1) {
        // 周
        ops = [
          { text: '一天', value: 0 },
          { text: '两天', value: 1 }
        ]
      } else if (this.form.period === 2){
        // 半月
        ops = [
          { text: '一天', value: 0 },
          { text: '三天', value: 2 }
        ]
      } else if (this.form.period === 3) {
        // 月
        ops = [
          { text: '一天', value: 0 },
          { text: '三天', value: 2 },
          { text: '五天', value: 3 },
        ]
      } else if (this.form.period === 4) {
        // 季度
        ops = [
          { text: '一天', value: 0 },
          { text: '三天', value: 2 },
          { text: '五天', value: 3 },
          { text: '七天', value: 4 },
        ]
      } else if (this.form.period === 5) {
        // 半年
        ops = [
          { text: '一天', value: 0 },
          { text: '三天', value: 2 },
          { text: '五天', value: 3 },
          { text: '七天', value: 4 },
        ]
      }
      return ops
    },
    maintCompanyUserExtra () {
      let { communityId } = this.publicParams
      return { communityId }
    }
  },
  mounted () {
    this.responseData && this.responseData.data && this.getPeriodDetail() // 后端不想重构 所以增加新的接口获取巡检频次相关字段
  },
  methods: {
    getPeriodDetail () {
      let id = this.responseData.data.id
      this.$axios.get(`${getPeriodDetailURL}?id=${id}`).then(res => {
        if (res.status === 100) {
          let { data } = res
          this.setInfo(data)
        }
      })
    },
    setInfo (periodDetail) {
      let { data } = this.responseData
      this.form = {
        isPatrol: data.isPatrol !== '' && data.isPatrol !== null ? data.isPatrol : 1,
        // type: data.patrolType ? data.patrolType : undefined,
        // bluetoothId: data.patrolBluetoothNo ? data.patrolBluetoothNo : '',
        period: data.patrolPeriod !== null && data.patrolPeriod.toString() ? data.patrolPeriod : 1,
        floatingDays: data.patrolFloatingDays ? data.patrolFloatingDays : 0,
        firstPatrolDate: data.firstPatrolDate ? data.firstPatrolDate : '',
        needMeasureDevice: data.needMeasureDevice ? data.needMeasureDevice : 0,
        patrolContent: data.patrolContent.length > 0 ? data.patrolContent : [{ content: '' }],
        measureContent: data.measureContent.length > 0 ? data.measureContent : [{ content: '', unit: '' }],
        patrolChargerUserId: data.patrolChargerUserId,
        periodNum: periodDetail.periodNum,
        timespan: periodDetail.timespan,
        timespanUnit: periodDetail.timespanUnit,
        allowAlbumUpload: periodDetail.allowAlbumUpload
      }
    },
    submitBefore (data) {
      if (this.publicParams.id) {
        data.id = this.publicParams.id
        if (data.isPatrol === 0) {
          delete data.patrolChargerUserId
        } else {
          // data.measureContent = JSON.stringify(data.measureContent)
          // data.patrolContent = JSON.stringify(data.patrolContent)
          let period = data.period
          let timespanUnit = data.timespanUnit
          //巡检间隔去乘于巡检频次要小于当前周期数-1
          let time = data.periodNum * data.timespan
          if (time === 0) {
            this.$alert('保存失败，巡检频次和巡检间隔设置有误，请重新输入')
            return false
          }
          if (timespanUnit === 1) {
            if (
              (period === 0 && time > 1 * 24) ||
            (period === 1 && time > 7 * 24) ||
            (period === 2 && time > 14 * 24) ||
            (period === 3 && time > 28 * 24) ||
            (period === 4 && time > 89 * 24) ||
            (period === 5 && time > 179* 24)
            ) {
              this.$alert('保存失败，巡检频次和巡检间隔设置有误，请重新输入')
              return false
            }
          } else if (timespanUnit === 2) {
            if (
              (period === 0 && time !== 1) ||
            (period === 1 && time > 7) ||
            (period === 2 && time > 14) ||
            (period === 3 && time > 28) ||
            (period === 4 && time > 89) ||
            (period === 5 && time > 179)
            ) {
              this.$alert('保存失败，巡检频次和巡检间隔设置有误，请重新输入')
              return false
            }
          }
        }
        // if (data.type !== 1) {
        //   delete data.bluetoothId
        // }
        return true
      } else {
        this.$alert('请先完善基础信息')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '基础信息'
        })
        return false
      }
    },
    submitSuccess (data) {
      return true
    },
    removePatrolContent (index) {
      this.form.patrolContent.splice(index, 1)
    },
    addPatrolContent () {
      this.form.patrolContent.push({ content: '' })
    },
    removeMeasureContent (index) {
      this.form.measureContent.splice(index, 1)
    },
    addMeasureContent () {
      this.form.measureContent.push({ content: '' })
    },
  },
  watch: {
    'form.period' (val) {
      if(this.changeCount !== 0) {
        console.log('11',this.form.floatingDays)
        this.form.floatingDays = val
        if(val===5){
          this.form.floatingDays = 4
        }
      }
      this.changeCount ++
      // this.form.floatingDays = val > 2 ? 2 : val === 2 ? 1 : val
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  margin-bottom: 10px;
  .v-control {
    margin-right: 15px;
  }
}
.content:last-child {
  margin-bottom: 0;
}
.content-wrapper {
  display: flex;
  justify-content: flex-start;

  .right-wrapper {
    margin-right: 30px;
  }

  .right-tip {
    margin-left: 15px;
  }
}
.content-wrapper-allowAlbumUpload {
  display: flex;
  .right-tip {
    margin-left:15px;
  }
}
</style>