<template>
  <div>
    <form-panel
      class="form-container"
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitUrl="submitUrl"
      submitText="保存"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
    >
      <el-form-item
        label="是否维保"
        prop="isMaint"
        :rules="[{ required: true, message: '请输入是否维保', trigger: 'change' }]"
      >
        <v-select v-model="form.isMaint" :options="maintenanceOps"></v-select>
      </el-form-item>
      <div v-if="form.isMaint !== 0">
        <!-- <el-form-item
          label="维保方式"
          prop="type"
          :rules="[{ required: true, message: '请输入维保方式', trigger: 'change' }]"
        >
          <v-select v-model="form.type" :options="maintenanceTypeOps"></v-select>
        </el-form-item>
        <el-form-item
          v-if="form.type == 1"
          label="蓝牙编号"
          prop="bluetoothId"
          :rules="[{ required: true, message: '请填写蓝牙编号', trigger: 'blur'}]"
        >
          <v-select2
            v-model="form.bluetoothId"
            placeholder="选择蓝牙"
            :subjoin="bluetoothExtra"
            v-bind="bluetoothParams"
          ></v-select2>
        </el-form-item> -->
        <el-form-item label="合同开始">
          <v-datepicker v-model="form.contractFrom" type="date"/>
        </el-form-item>
        <el-form-item label="合同结束">
          <v-datepicker v-model="form.contractTo" type="date"/>
        </el-form-item>
        <el-form-item
          label="维保负责人"
          prop="maintChargerUserId"
          :rules="[{ required: true, message: '请输入维保负责人', trigger: 'change' }]"
        >
          <v-select2
            v-model="form.maintChargerUserId"
            placeholder="维保负责人"
            :subjoin="maintCompanyUserExtra"
            v-bind="maintChargerUserParams"
          ></v-select2>
        </el-form-item>
        <el-form-item
          label="维保周期"
          prop="period"
          :rules="[{ required: true, message: '请输入维保周期', trigger: 'change' }]"
        >
          <v-select v-model="form.period" :options="periodOps"></v-select>
        </el-form-item>
        <el-form-item
          label="维保提醒"
          prop="floatingDays"
          :rules="[{ required: true, message: '请输入维保提醒', trigger: 'change' }]"
        >
          <v-select v-model="form.floatingDays" :options="floatingDaysOps"></v-select>
        </el-form-item>
        <el-form-item
          label="首次维保日期"
          prop="firstMaintDate"
          :rules="[{ required: true, message: '请输入首次维保日期', trigger: 'blur' }]"
        >
        <div class="content-wrapper">
          <v-datepicker v-model="form.firstMaintDate" type="date"/>
          <div class="right-tip">
            <el-form-item label="是否允许相册上传图片" prop="allowAlbumUpload">
              <el-switch v-model="form.allowAlbumUpload" :active-value="1" :inactive-value="0" active-color="#409EFF"/>
            </el-form-item>               
          </div>
        </div>
        </el-form-item>
        <el-form-item label="维保单位">
          <v-select2
            v-model="form.maintCompanyId"
            placeholder="维保单位"
            :subjoin="maintCompanyUserExtra"
            v-bind="maintCompanyParams"
            @onChange="maintCompanyChange"
          ></v-select2>
        </el-form-item>
        <el-form-item label="维保单位分类">
          <v-select v-model="form.maintCompanyType" :options="maintCompanyTypeOps"></v-select>
        </el-form-item>
        <el-form-item label="外包类型">
          <v-select v-model="form.outsourcingType" :options="outsourcingTypeOps"></v-select>
        </el-form-item>
        <el-form-item label="联系人">{{form.maintUser}}</el-form-item>
        <el-form-item label="联系电话">{{form.maintUserPhone}}</el-form-item>
        <el-form-item label="维保内容"><div v-for="(item, index) in maintContent" :key="index">{{index+1}}.{{item}}</div></el-form-item>
      </div>
    </form-panel>
  </div>
</template>
<script>
import {
  saveDeviceMaintURL,
  getBlueteethURL,
  getMaintenanceUnitURL,
  getUsernameURL
} from './../api'
import {
  maintenanceOps,
  maintenanceTypeOps,
  periodOps,
  maintCompanyTypeOps,
  outsourcingTypeOps
} from './../map'
export default {
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      maintenanceOps,
      maintenanceTypeOps,
      periodOps,
      maintCompanyTypeOps,
      outsourcingTypeOps,
      submitUrl: saveDeviceMaintURL,
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      form: {
        isMaint: 1,
        // type: undefined,
        // bluetoothId: '',
        contractFrom: '',
        contractTo: '',
        period: 1,
        floatingDays: 1,
        firstMaintDate: '',
        maintCompanyId: '',
        maintCompanyType: undefined,
        outsourcingType: undefined,
        maintUser: '',
        maintUserPhone: '',
        allowAlbumUpload: 1
      },
      bluetoothParams: {
        searchUrl: getBlueteethURL,
        request: {
          text: 'serialNum',
          value: 'id'
        },
        response: {
          text: 'serialNum',
          value: 'id'
        }
      },
      maintChargerUserParams: {
        searchUrl: getUsernameURL,
        request: {
          text: 'username',
          value: 'userId'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      maintCompanyParams: {
        searchUrl: getMaintenanceUnitURL,
        request: {
          text: 'content',
          value: 'id'
        },
        response: {
          text: 'content',
          value: 'id'
        }
      },
      maintContent: []
    }
  },
  activated () {
    this.$nextTick(() => {
      this.maintContent = this.publicParams.maintContent
    })
  },
  computed: {
    bluetoothExtra () {
      let { communityId, area } = this.publicParams
      return { communityId, area }
    },
    maintCompanyExtra () {
      let { categoryId } = this.publicParams
      return { categoryId }
    },
    maintCompanyUserExtra () {
      let { communityId } = this.publicParams
      return { communityId }
    },
    floatingDaysOps () {
      let ops = []
      if (this.form.period > 2) {
        ops = [{ text: '三天', value: 2 }]
      } else {
        ops = [{ text: '一天', value: 1 }]
      }
      return ops
    }
  },
  mounted () {
    this.responseData && this.responseData.data && this.setInfo()
  },
  methods: {
    setInfo () {
      let { data } = this.responseData
      this.form = {
        isMaint: data.isMain !== '' && data.isMain !== null ? data.isMain : 1,
        // type: data.type ? data.type : undefined,
        // bluetoothId: '',
        contractFrom: data.contractFrom,
        contractTo: data.contractTo,
        period: data.period ? data.period : 1,
        floatingDays: data.floatingDays ? data.floatingDays : 1,
        firstMaintDate: data.firstMaintDate,
        maintCompanyId: data.maintCompanyId,
        maintCompanyType: data.maintCompanyType || undefined,
        outsourcingType: data.outsourcingType || undefined,
        maintUser: data.maintUserName,
        maintUserPhone: data.maintCompanyTel,
        maintChargerUserId: data.maintChargerUserId,
        allowAlbumUpload: data.allowAlbumUpload
      }
      // if (data.type === 1) {
      //   this.$nextTick(() => {
      //     this.form.bluetoothId = data.bluetoothNo
      //   }, 20)
      // }
    },
    submitBefore (data) {
      if (this.publicParams.id) {
        if (data.isMaint === 0) {
          return {
            id: this.publicParams.id,
            isMaint: 0
          }
        } else {
          data.id = this.publicParams.id
          // if (data.type !== 1) {
          //   delete data.bluetoothId
          // }
          return true
        }
      } else {
        this.$alert('请先完善基础信息')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '基础信息'
        })
        return false
      }
    },
    submitSuccess (data) {
      this.$emit('contact', {
        method: 'setActiveLabel',
        data: '巡检信息'
      })
      return false
    },
    maintCompanyChange (val, isFirst) {
      if (val && !isFirst) {
        this.form.maintUser = val.contact
        this.form.maintUserPhone = val.phone
      }
    }
  },
  watch: {
    'form.period' (val) {
      this.form.floatingDays = val > 2 ? 2 : 1
    }
  }
}
</script>
<style scoped lang="scss">
.content-wrapper {
  display: flex;
  .right-tip {
    margin-left:15px;
  }
}
</style>
