var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            staticClass: "form-container",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "安装日期",
                prop: "installDate",
                rules: [
                  {
                    required: true,
                    message: "请输入安装日期",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-datepicker", {
                attrs: { type: "date" },
                model: {
                  value: _vm.form.installDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "installDate", $$v)
                  },
                  expression: "form.installDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "交付日期",
                prop: "handOverDate",
                rules: [
                  {
                    required: true,
                    message: "请输入交付日期",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-datepicker", {
                attrs: { type: "date" },
                model: {
                  value: _vm.form.handOverDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "handOverDate", $$v)
                  },
                  expression: "form.handOverDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "始用日期",
                prop: "startUserdDate",
                rules: [
                  {
                    required: true,
                    message: "请输入始用日期",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-datepicker", {
                attrs: { type: "date" },
                model: {
                  value: _vm.form.startUserdDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "startUserdDate", $$v)
                  },
                  expression: "form.startUserdDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "年检时间" } },
            [
              _c("v-datepicker", {
                attrs: { type: "date" },
                model: {
                  value: _vm.form.annualCheckDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "annualCheckDate", $$v)
                  },
                  expression: "form.annualCheckDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "质保结束",
                prop: "qualityAssuranceDate",
                rules: [
                  {
                    required: true,
                    message: "请输入质保结束",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-datepicker", {
                attrs: { type: "date" },
                model: {
                  value: _vm.form.qualityAssuranceDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "qualityAssuranceDate", $$v)
                  },
                  expression: "form.qualityAssuranceDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "注册代码" } },
            [
              _c("v-input", {
                attrs: { width: 385 },
                model: {
                  value: _vm.form.registerCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "registerCode", $$v)
                  },
                  expression: "form.registerCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "品牌" } },
            [
              _c("v-input", {
                attrs: { width: 385 },
                model: {
                  value: _vm.form.brand,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "brand", $$v)
                  },
                  expression: "form.brand",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "出厂编号" } },
            [
              _c("v-input", {
                attrs: { width: 385 },
                model: {
                  value: _vm.form.factoryNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "factoryNumber", $$v)
                  },
                  expression: "form.factoryNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "设备型号",
                prop: "model",
                rules: [
                  {
                    required: true,
                    message: "请输入设备型号",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("v-input", {
                attrs: { width: 385 },
                model: {
                  value: _vm.form.model,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "model", $$v)
                  },
                  expression: "form.model",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "产地" } },
            [
              _c("v-select", {
                attrs: { options: _vm.originOps },
                model: {
                  value: _vm.form.origin,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "origin", $$v)
                  },
                  expression: "form.origin",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "制造商" } },
            [
              _c("v-input", {
                attrs: { width: 385 },
                model: {
                  value: _vm.form.manufactureCompany,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "manufactureCompany", $$v)
                  },
                  expression: "form.manufactureCompany",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "出厂日期" } },
            [
              _c("v-datepicker", {
                attrs: { type: "date" },
                model: {
                  value: _vm.form.productionDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "productionDate", $$v)
                  },
                  expression: "form.productionDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "供应商" } },
            [
              _c("v-input", {
                attrs: { width: 385 },
                model: {
                  value: _vm.form.businessName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "businessName", $$v)
                  },
                  expression: "form.businessName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "额定电压" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.electricVoltage,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "electricVoltage", $$v)
                  },
                  expression: "form.electricVoltage",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "额定电流" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.electricCurrent,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "electricCurrent", $$v)
                  },
                  expression: "form.electricCurrent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "频率" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.frequency,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "frequency", $$v)
                  },
                  expression: "form.frequency",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "功率" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.capacityFactor,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "capacityFactor", $$v)
                  },
                  expression: "form.capacityFactor",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "其他技术参数" } },
            [
              _c("v-textarea", {
                attrs: { length: 200 },
                model: {
                  value: _vm.form.otherParams,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "otherParams", $$v)
                  },
                  expression: "form.otherParams",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }