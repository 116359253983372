var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            staticClass: "form-container",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否巡检",
                prop: "isPatrol",
                rules: [
                  {
                    required: true,
                    message: "请输入是否巡检",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.maintenanceOps },
                model: {
                  value: _vm.form.isPatrol,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isPatrol", $$v)
                  },
                  expression: "form.isPatrol",
                },
              }),
            ],
            1
          ),
          _vm.form.isPatrol !== 0
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "巡检负责人",
                        prop: "patrolChargerUserId",
                        rules: [
                          {
                            required: true,
                            message: "请输入巡检负责人",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "巡检负责人",
                              subjoin: _vm.maintCompanyUserExtra,
                            },
                            model: {
                              value: _vm.form.patrolChargerUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "patrolChargerUserId", $$v)
                              },
                              expression: "form.patrolChargerUserId",
                            },
                          },
                          "v-select2",
                          _vm.maintChargerUserParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "巡检频次", required: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "period",
                                rules: {
                                  required: true,
                                  message: "当前选项不允许为空",
                                  trigger: "change",
                                },
                              },
                            },
                            [
                              _c("v-select", {
                                staticClass: "right-wrapper",
                                attrs: {
                                  options: _vm.periodFormOps,
                                  width: 182,
                                },
                                model: {
                                  value: _vm.form.period,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "period", $$v)
                                  },
                                  expression: "form.period",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "periodNum",
                                rules: {
                                  required: true,
                                  message: "内容不允许为空",
                                  trigger: "blur",
                                },
                              },
                            },
                            [
                              _c("v-input-number", {
                                attrs: {
                                  controls: "",
                                  "controls-position": "right",
                                  placeholder: "1",
                                  min: 0,
                                  max: 99,
                                  width: 182,
                                },
                                model: {
                                  value: _vm.form.periodNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "periodNum", $$v)
                                  },
                                  expression: "form.periodNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "right-tip" }, [
                            _vm._v("如：“日、3”表示每日巡检3次"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "巡检间隔", required: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "timespan",
                                rules: {
                                  required: true,
                                  message: "内容不允许为空",
                                  trigger: "blur",
                                },
                              },
                            },
                            [
                              _c("v-input-number", {
                                staticClass: "right-wrapper",
                                attrs: {
                                  controls: "",
                                  "controls-position": "right",
                                  placeholder: "1",
                                  min: 0,
                                  max: 9999999999,
                                  width: 182,
                                },
                                model: {
                                  value: _vm.form.timespan,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "timespan", $$v)
                                  },
                                  expression: "form.timespan",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "timespanUnit",
                                rules: {
                                  required: true,
                                  message: "当前选项不允许为空",
                                  trigger: "change",
                                },
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.timespanUnitOps,
                                  width: 182,
                                },
                                model: {
                                  value: _vm.form.timespanUnit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "timespanUnit", $$v)
                                  },
                                  expression: "form.timespanUnit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "right-tip" }, [
                            _vm._v("两次巡检间隔最小时间"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "提醒时间",
                        prop: "floatingDays",
                        rules: [
                          {
                            required: true,
                            message: "请输入维保提醒",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.floatingDaysOps },
                        model: {
                          value: _vm.form.floatingDays,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "floatingDays", $$v)
                          },
                          expression: "form.floatingDays",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "首次巡检日期",
                        prop: "firstPatrolDate",
                        rules: [
                          {
                            required: true,
                            message: "请输入首次巡检日期",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-wrapper-allowAlbumUpload" },
                        [
                          _c("v-datepicker", {
                            attrs: { type: "date" },
                            model: {
                              value: _vm.form.firstPatrolDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "firstPatrolDate", $$v)
                              },
                              expression: "form.firstPatrolDate",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "right-tip" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "是否允许相册上传图片",
                                    prop: "allowAlbumUpload",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-color": "#409EFF",
                                    },
                                    model: {
                                      value: _vm.form.allowAlbumUpload,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "allowAlbumUpload",
                                          $$v
                                        )
                                      },
                                      expression: "form.allowAlbumUpload",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "巡检内容" } },
                    [
                      _c(
                        "div",
                        _vm._l(
                          _vm.commmonPatrolContent,
                          function (item, indexp) {
                            return _c("div", { key: indexp }, [
                              _vm._v(_vm._s(indexp + 1) + "." + _vm._s(item)),
                            ])
                          }
                        ),
                        0
                      ),
                      _vm._l(_vm.form.patrolContent, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "content" },
                          [
                            _c("v-input", {
                              attrs: { width: 385, maxlength: 300 },
                              model: {
                                value: item.content,
                                callback: function ($$v) {
                                  _vm.$set(item, "content", $$v)
                                },
                                expression: "item.content",
                              },
                            }),
                            _vm.form.patrolContent.length > 1
                              ? _c("v-button", {
                                  attrs: { type: "danger", text: "删除" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removePatrolContent(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                            index === _vm.form.patrolContent.length - 1
                              ? _c("v-button", {
                                  attrs: { text: "添加巡检内容" },
                                  on: { click: _vm.addPatrolContent },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否抄表",
                        prop: "needMeasureDevice",
                        rules: [
                          {
                            required: true,
                            message: "请输入是否抄表",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.maintenanceOps },
                        model: {
                          value: _vm.form.needMeasureDevice,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "needMeasureDevice", $$v)
                          },
                          expression: "form.needMeasureDevice",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.needMeasureDevice === 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "抄表内容 " } },
                        [
                          _c(
                            "div",
                            _vm._l(
                              _vm.commmonMeasureContent,
                              function (item, indexp) {
                                return _c("div", { key: indexp }, [
                                  _vm._v(
                                    _vm._s(indexp + 1) + "." + _vm._s(item)
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                          _vm._l(
                            _vm.form.measureContent,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "content" },
                                [
                                  _c("v-input", {
                                    attrs: {
                                      placeholder: "抄表内容",
                                      width: 385,
                                      maxlength: 300,
                                    },
                                    model: {
                                      value: item.content,
                                      callback: function ($$v) {
                                        _vm.$set(item, "content", $$v)
                                      },
                                      expression: "item.content",
                                    },
                                  }),
                                  _c("v-input", {
                                    attrs: { placeholder: "单位" },
                                    model: {
                                      value: item.unit,
                                      callback: function ($$v) {
                                        _vm.$set(item, "unit", $$v)
                                      },
                                      expression: "item.unit",
                                    },
                                  }),
                                  _vm.form.measureContent.length > 1
                                    ? _c("v-button", {
                                        attrs: { type: "danger", text: "删除" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeMeasureContent(
                                              index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  index === _vm.form.measureContent.length - 1
                                    ? _c("v-button", {
                                        attrs: { text: "添加抄表内容" },
                                        on: { click: _vm.addMeasureContent },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }