<template>
  <div class="baseInfo-container">
    <form-panel
      class="form-container"
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitUrl="submitUrl"
      submitText="保存"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
    >
      <el-form-item
        label="设备名称"
        prop="name"
        :rules="[{ required: true, message: '请输入设备名称', trigger: 'blur' }]"
      >
        <v-input v-model="form.name" :width="300" placeholder="限30个字符" :maxlength="30"/>
      </el-form-item>
      <el-form-item
        label="设备编号"
        prop="serialNum"
        :rules="[{ required: true, message: '请输入设备编号', trigger: 'blur' }]"
      >
        <v-input v-model="form.serialNum" :width="300" placeholder="限30个字符" :maxlength="30"/>
      </el-form-item>
      <el-form-item
        label="设备状态"
        prop="deviceStatus"
        :rules="[{ required: true, message: '请选择设备状态', trigger: 'change'}]"
      >
        <v-select v-model="form.deviceStatus" :options="deviceStatusOps2" :width="width"></v-select>
      </el-form-item>
      <el-form-item
        label="所属项目"
        prop="communityId"
        :rules="[{ required: true, message: '请选择所属项目', trigger: 'blur'}]"
      >
        <v-select2
          v-model="form.communityId"
          placeholder="查询所属项目"
          v-bind="communityParams"
          :width="width"
          @onChange="communityChange"
        ></v-select2>
      </el-form-item>
      <el-form-item label="所属组团">
        <v-select v-model="form.area" :options="areaOps" @change="areaChange" :width="width"></v-select>
      </el-form-item>
      <el-form-item
        label="安装位置"
        prop="address"
        :rules="[{ required: true, message: '请输入安装位置', trigger: 'blur' }]"
      >
        <v-input v-model="form.address" :width="300" :maxlength="30" placeholder="限30个字符"/>
      </el-form-item>
      <div style="display: flex;">
        <el-form-item
          label="设备经纬度"
          prop="longitude"
        >
          <v-input v-model="form.longitude" placeholder="请输入经度" :width="140" />
        </el-form-item>
        <el-form-item
          label-width="20px"
          prop="latitude"
        >
          <v-input v-model="form.latitude" placeholder="请输入纬度" :width="140" />
        </el-form-item>&nbsp;&nbsp;
        <col2-item><v-button text="地图标注" @click="getMap" /></col2-item>
      </div>
      <el-form-item
        label="设备安装高度"
        prop="altitude"
      >
        <v-input-number
          v-model="form.altitude"
          placeholder="请输入设备安装高度"
          controls
          controlsPosition="right"
          :precision="2"
          :max="99999"
          :min="0"
          :width="200"
        />&nbsp;&nbsp;M
      </el-form-item>
      <el-form-item
        label="巡检/维保方式"
        prop="workType"
        :rules="[{ required: true, message: '请选择巡检/维保方式', trigger: 'change' }]"
      >
        <v-select
          v-model="form.workType"
          :options="patrolMaintenanceTypeOps"
          :width="width"
          >
        </v-select>
      </el-form-item>
      <el-form-item
          v-if="form.workType === 1"
          label="蓝牙编号"
          prop="bluetoothId"
          :rules="[{ required: true, message: '请选择蓝牙编号', trigger: 'blur'}]"
        >
          <v-select2
            v-model="form.bluetoothId"
            placeholder="查询蓝牙编号"
            :subjoin="bluetoothExtra"
            v-bind="bluetoothParams"
            :width="width"
            :disabled="!form.communityId || form.communityId.length === 0"
            ></v-select2>
      </el-form-item>
      <el-form-item label="所属设备房">{{deviceRoomName}}</el-form-item>
      <el-form-item
        label="设备分类"
        prop="categoryId"
        :rules="[{ required: true, message: '请输入设备分类', trigger: 'change1' }]"
      >
        <div class="category">
          <v-select v-model="firstCategory" :options="categoryList" :width="width"></v-select>
          <v-select2
            v-model="secondCategory"
            placeholder="二级分类"
            :subjoin="secondExtraParams"
            v-bind="categoryParams"
            :width="width"
          ></v-select2>
          <v-select2
            v-model="form.categoryId"
            placeholder="三级分类"
            :subjoin="thirdExtraParams"
            v-bind="categoryParams"
            @onChange="categoryIdChange"
            :width="width"
          ></v-select2>
        </div>
      </el-form-item>
      <el-form-item label="设备封面">
        <v-uploader ref="myUpload" :action="uploadimgURL" :imgUrls.sync="form.picUrl" operate="none">
          <div slot="rightTip">注：建议宽400px高400px，图片小于512KB</div>
        </v-uploader>
      </el-form-item>
      <el-form-item label="责任人">
        <v-input v-model="form.chargerUserName" :maxlength="8" :width="width" placeholder="限8个字符"/>
      </el-form-item>
      <el-form-item label="责任人手机号" prop="chargerUserPhone" :rules="[{ required: false, validator: validatePhone, trigger: 'blur' }]">
        <v-input v-model="form.chargerUserPhone" :width="width" placeholder="请输入责任人手机号"/>
      </el-form-item>
      <el-form-item label="备注">
        <v-ueditor v-model="form.remarks"></v-ueditor>
      </el-form-item>
    </form-panel>
    <v-map
      :selectedLng="Number(form.longitude)"
      :selectedLat="Number(form.latitude)"
      :showMapDialog.sync="showMapDialog"
      @setLngAndLat="setLngAndLat"
    ></v-map>
  </div>
</template>
<script>
import {
  saveDeviceBaseURL,
  updateDeviceBaseURL,
  uploadimgURL,
  getCategoryListURL,
  getCategoryListByParentURL,
  queryCommonContentURL,
  getBlueteethURL
} from './../api'
import { deviceStatusOps, patrolMaintenanceTypeOps } from './../map'
import { vUploader, vUeditor, vMap } from 'components/control'
import { Col2Item } from '@/components/bussiness'
import { mobileRegular } from 'common/regular'
import { normalCommunityParams } from 'common/select2Params'
import { select2BlockURL } from 'common/api'
export default {
  components: {
    vUploader,
    vUeditor,
    Col2Item,
    vMap
  },
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      width: 180,
      uploadimgURL,
      patrolMaintenanceTypeOps: patrolMaintenanceTypeOps(2),
      form: {
        name: '',
        serialNum: '',
        deviceStatus: 1,
        communityId: '',
        area: undefined,
        address: '',
        longitude: '',
        latitude: '',
        altitude: '',
        categoryId: '',
        picUrl: '',
        chargerUserName: '',
        chargerUserPhone: '',
        remarks: '',
        workType: undefined,
        bluetoothId: ''
      },
      deviceRoomName: '',
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      communityParams: normalCommunityParams,
      categoryParams: {
        searchUrl: getCategoryListByParentURL
      },
      // secondExtraParams: {
      //   superId: ''
      // },
      // thirdExtraParams: {
      //   superId: ''
      // },
      bluetoothParams: {
        searchUrl: getBlueteethURL,
        request: {
          text: 'serialNum',
          value: 'id'
        },
        response: {
          text: 'serialNum',
          value: 'id'
        }
      },
      thirdCategoryParams: {},
      areaOps: [],
      categoryList: [],
      firstCategory: undefined,
      secondCategory: '',
      coverImg: [],
      showMapDialog: false
    }
  },
  computed: {
    deviceStatusOps2 () {
      let [a, ...ops] = deviceStatusOps; // eslint-disable-line
      return ops
    },
    bluetoothExtra () {
      let { communityId, area } = this.publicParams
      return { communityId, area }
    },
    submitUrl () {
      let url = this.publicParams && this.publicParams.id ? updateDeviceBaseURL : saveDeviceBaseURL
      return url
    },
    secondExtraParams () {
      return {
        superId: this.firstCategory
      }
    },
    thirdExtraParams () {
      return {
        superId: this.secondCategory
      }
    }
  },
  mounted () {
    this.getCategoryList()
    this.responseData && this.responseData.data && this.setInfo()
  },
  methods: {
    getMap () {
      this.showMapDialog = true
    },
    setLngAndLat (val) {
      this.form.longitude = val.lng
      this.form.latitude = val.lat
      this.showMapDialog = false
    },
    setInfo () {
      let { data } = this.responseData
      console.log('datadatadatadata', data)

      let keyArr = Object.keys(this.form)
      let newObj = {}
      keyArr.forEach(value => {
        if (value === 'categoryId') {
          newObj[value] = data.thridLevel
        } else if (value === 'remarks') {
          newObj[value] = data[value] || ''
        } else {
          newObj[value] = data[value]
        }
      })
      newObj.workType = newObj.workType || undefined
      newObj.area = newObj.area || undefined
      this.$nextTick(() => {
        this.form = newObj
        this.firstCategory = data.firstLevel
        this.secondCategory = data.secondLevel
        this.publicParams.communityId = data.communityId
        this.publicParams.categoryId = data.thridLevel
        this.publicParams.area = data.area
        this.deviceRoomName = data.deviceRoomName
        // this.coverImg = [{ url: data.picUrl }]
        this.getAreaList(data.communityId, data.area)
      })
    },
    getCategoryList () {
      this.$axios.get(`${getCategoryListURL}?codeLevel=1`).then(res => {
        if (res.status === '100') {
          let { data } = res
          if (data) {
            this.categoryList = data.map(value => {
              return {
                text: value.name,
                value: value.id
              }
            })
            if (!this.$route.query.id) {
              this.firstCategory = data[0].id
            }
          }
        }
      })
    },
    communityChange (val, isFirst) {
      if (!isFirst && val) {
        this.getAreaList(val.id)
        if (this.publicParams.categoryId) {
          this.$axios.post(queryCommonContentURL, { categoryId: this.publicParams.categoryId, communityId: val.id }).then(res => {
            if (res.status === '100') {
              let { data } = res
              this.publicParams.maintContent = data.maintContent
              this.publicParams.commmonMeasureContent = data.commmonMeasureContent
              this.publicParams.commmonPatrolContent = data.commmonPatrolContent
            }
          })
        }
      }
    },
    areaChange (val) {
      this.publicParams.area = val
    },
    categoryIdChange (val, isFirst) {
      if (!isFirst && val) {
        this.publicParams.categoryId = val.id
        if (this.form.communityId) {
          this.$axios.post(queryCommonContentURL, { categoryId: val.id, communityId: this.form.communityId }).then(res => {
            if (res.status === '100') {
              let { data } = res
              this.publicParams.maintContent = data.maintContent
              this.publicParams.commmonMeasureContent = data.commmonMeasureContent
              this.publicParams.commmonPatrolContent = data.commmonPatrolContent
            }
          })
        }
      }
    },
    getAreaList (id, val) {
      this.$axios(`${select2BlockURL}?communityId=${id}`).then(res => {
        if (res.status === 100) {
          let ops = res.data.map(item => {
            return {
              text: item.name,
              value: item.name
            }
          })
          this.areaOps = [{ text: '无', value: '无' }, ...ops]
          this.form.area = val || undefined
        }
      })
    },
    submitBefore (data) {
      this.publicParams.id && (data.id = this.publicParams.id)
      // this.coverImg.length > 0 && (data.picUrl = this.coverImg[0].url)
      if (data.workType !== 1) {
        delete data.bluetoothId
      }
      return true
    },
    submitSuccess (data) {
      data && (this.publicParams.id = data)
      this.$emit('contact', {
        method: 'setActiveLabel',
        data: '参数信息'
      })
      return false
    },
    validatePhone (rule, value, callback) {
      if (value && !mobileRegular.test(value)) {
        callback(new Error('手机号不正确'))
      } else {
        callback()
      }
    }
  },
  watch: {
    // firstCategory (newVal) {
    //   this.$set(this.secondExtraParams, 'superId', newVal)
    // },
    // secondCategory (newVal) {
    //   this.$set(this.thirdExtraParams, 'superId', newVal)
    // },
    'form.communityId' (val) {
      this.publicParams.communityId = val
      if (!val) {
        this.areaOps = [{ text: '无', value: '无' }]
        this.form.area = undefined
      }
    },
    'form.area' (val) {
      this.publicParams.area = val
    }
  }
}
</script>
<style lang="scss" scoped>
.category {
  .v-control {
    display: inline-block;
    margin-right: 15px;
  }
}
</style>
<style scoped>
>>> .is-error .dropdown-toggle {
  border-color: #f56c6c !important;
}
</style>
