var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfo-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            staticClass: "form-container",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "设备名称",
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: "请输入设备名称",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("v-input", {
                attrs: { width: 300, placeholder: "限30个字符", maxlength: 30 },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "设备编号",
                prop: "serialNum",
                rules: [
                  {
                    required: true,
                    message: "请输入设备编号",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("v-input", {
                attrs: { width: 300, placeholder: "限30个字符", maxlength: 30 },
                model: {
                  value: _vm.form.serialNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "serialNum", $$v)
                  },
                  expression: "form.serialNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "设备状态",
                prop: "deviceStatus",
                rules: [
                  {
                    required: true,
                    message: "请选择设备状态",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.deviceStatusOps2, width: _vm.width },
                model: {
                  value: _vm.form.deviceStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deviceStatus", $$v)
                  },
                  expression: "form.deviceStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "所属项目",
                prop: "communityId",
                rules: [
                  {
                    required: true,
                    message: "请选择所属项目",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "v-select2",
                _vm._b(
                  {
                    attrs: { placeholder: "查询所属项目", width: _vm.width },
                    on: { onChange: _vm.communityChange },
                    model: {
                      value: _vm.form.communityId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "communityId", $$v)
                      },
                      expression: "form.communityId",
                    },
                  },
                  "v-select2",
                  _vm.communityParams,
                  false
                )
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属组团" } },
            [
              _c("v-select", {
                attrs: { options: _vm.areaOps, width: _vm.width },
                on: { change: _vm.areaChange },
                model: {
                  value: _vm.form.area,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "area", $$v)
                  },
                  expression: "form.area",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "安装位置",
                prop: "address",
                rules: [
                  {
                    required: true,
                    message: "请输入安装位置",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("v-input", {
                attrs: { width: 300, maxlength: 30, placeholder: "限30个字符" },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "设备经纬度", prop: "longitude" } },
                [
                  _c("v-input", {
                    attrs: { placeholder: "请输入经度", width: 140 },
                    model: {
                      value: _vm.form.longitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "longitude", $$v)
                      },
                      expression: "form.longitude",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "20px", prop: "latitude" } },
                [
                  _c("v-input", {
                    attrs: { placeholder: "请输入纬度", width: 140 },
                    model: {
                      value: _vm.form.latitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "latitude", $$v)
                      },
                      expression: "form.latitude",
                    },
                  }),
                ],
                1
              ),
              _vm._v("  \n      "),
              _c(
                "col2-item",
                [
                  _c("v-button", {
                    attrs: { text: "地图标注" },
                    on: { click: _vm.getMap },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设备安装高度", prop: "altitude" } },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入设备安装高度",
                  controls: "",
                  controlsPosition: "right",
                  precision: 2,
                  max: 99999,
                  min: 0,
                  width: 200,
                },
                model: {
                  value: _vm.form.altitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "altitude", $$v)
                  },
                  expression: "form.altitude",
                },
              }),
              _vm._v("  M\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "巡检/维保方式",
                prop: "workType",
                rules: [
                  {
                    required: true,
                    message: "请选择巡检/维保方式",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.patrolMaintenanceTypeOps,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.workType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "workType", $$v)
                  },
                  expression: "form.workType",
                },
              }),
            ],
            1
          ),
          _vm.form.workType === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "蓝牙编号",
                    prop: "bluetoothId",
                    rules: [
                      {
                        required: true,
                        message: "请选择蓝牙编号",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        attrs: {
                          placeholder: "查询蓝牙编号",
                          subjoin: _vm.bluetoothExtra,
                          width: _vm.width,
                          disabled:
                            !_vm.form.communityId ||
                            _vm.form.communityId.length === 0,
                        },
                        model: {
                          value: _vm.form.bluetoothId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bluetoothId", $$v)
                          },
                          expression: "form.bluetoothId",
                        },
                      },
                      "v-select2",
                      _vm.bluetoothParams,
                      false
                    )
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("el-form-item", { attrs: { label: "所属设备房" } }, [
            _vm._v(_vm._s(_vm.deviceRoomName)),
          ]),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "设备分类",
                prop: "categoryId",
                rules: [
                  {
                    required: true,
                    message: "请输入设备分类",
                    trigger: "change1",
                  },
                ],
              },
            },
            [
              _c(
                "div",
                { staticClass: "category" },
                [
                  _c("v-select", {
                    attrs: { options: _vm.categoryList, width: _vm.width },
                    model: {
                      value: _vm.firstCategory,
                      callback: function ($$v) {
                        _vm.firstCategory = $$v
                      },
                      expression: "firstCategory",
                    },
                  }),
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        attrs: {
                          placeholder: "二级分类",
                          subjoin: _vm.secondExtraParams,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.secondCategory,
                          callback: function ($$v) {
                            _vm.secondCategory = $$v
                          },
                          expression: "secondCategory",
                        },
                      },
                      "v-select2",
                      _vm.categoryParams,
                      false
                    )
                  ),
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        attrs: {
                          placeholder: "三级分类",
                          subjoin: _vm.thirdExtraParams,
                          width: _vm.width,
                        },
                        on: { onChange: _vm.categoryIdChange },
                        model: {
                          value: _vm.form.categoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "categoryId", $$v)
                          },
                          expression: "form.categoryId",
                        },
                      },
                      "v-select2",
                      _vm.categoryParams,
                      false
                    )
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设备封面" } },
            [
              _c(
                "v-uploader",
                {
                  ref: "myUpload",
                  attrs: {
                    action: _vm.uploadimgURL,
                    imgUrls: _vm.form.picUrl,
                    operate: "none",
                  },
                  on: {
                    "update:imgUrls": function ($event) {
                      return _vm.$set(_vm.form, "picUrl", $event)
                    },
                    "update:img-urls": function ($event) {
                      return _vm.$set(_vm.form, "picUrl", $event)
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "rightTip" }, slot: "rightTip" }, [
                    _vm._v("注：建议宽400px高400px，图片小于512KB"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "责任人" } },
            [
              _c("v-input", {
                attrs: {
                  maxlength: 8,
                  width: _vm.width,
                  placeholder: "限8个字符",
                },
                model: {
                  value: _vm.form.chargerUserName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "chargerUserName", $$v)
                  },
                  expression: "form.chargerUserName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "责任人手机号",
                prop: "chargerUserPhone",
                rules: [
                  {
                    required: false,
                    validator: _vm.validatePhone,
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("v-input", {
                attrs: { width: _vm.width, placeholder: "请输入责任人手机号" },
                model: {
                  value: _vm.form.chargerUserPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "chargerUserPhone", $$v)
                  },
                  expression: "form.chargerUserPhone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注" } },
            [
              _c("v-ueditor", {
                model: {
                  value: _vm.form.remarks,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remarks", $$v)
                  },
                  expression: "form.remarks",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-map", {
        attrs: {
          selectedLng: Number(_vm.form.longitude),
          selectedLat: Number(_vm.form.latitude),
          showMapDialog: _vm.showMapDialog,
        },
        on: {
          "update:showMapDialog": function ($event) {
            _vm.showMapDialog = $event
          },
          "update:show-map-dialog": function ($event) {
            _vm.showMapDialog = $event
          },
          setLngAndLat: _vm.setLngAndLat,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }