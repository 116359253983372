<template>
  <div>
    <form-panel
      class="form-container"
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitUrl="submitUrl"
      submitText="保存"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
    >
      <el-form-item
        label="安装日期"
        prop="installDate"
        :rules="[{ required: true, message: '请输入安装日期', trigger: 'change' }]"
      >
        <v-datepicker v-model="form.installDate" type="date"/>
      </el-form-item>
      <el-form-item
        label="交付日期"
        prop="handOverDate"
        :rules="[{ required: true, message: '请输入交付日期', trigger: 'change' }]"
      >
        <v-datepicker v-model="form.handOverDate" type="date"/>
      </el-form-item>
      <el-form-item
        label="始用日期"
        prop="startUserdDate"
        :rules="[{ required: true, message: '请输入始用日期', trigger: 'change' }]"
      >
        <v-datepicker v-model="form.startUserdDate" type="date"/>
      </el-form-item>
      <el-form-item label="年检时间">
        <v-datepicker v-model="form.annualCheckDate" type="date"/>
      </el-form-item>
      <el-form-item
        label="质保结束"
        prop="qualityAssuranceDate"
        :rules="[{ required: true, message: '请输入质保结束', trigger: 'change' }]"
      >
        <v-datepicker v-model="form.qualityAssuranceDate" type="date"/>
      </el-form-item>
      <el-form-item label="注册代码">
        <v-input v-model="form.registerCode" :width="385"/>
      </el-form-item>
      <el-form-item label="品牌">
        <v-input v-model="form.brand" :width="385"/>
      </el-form-item>
      <el-form-item label="出厂编号">
        <v-input v-model="form.factoryNumber" :width="385"/>
      </el-form-item>
      <el-form-item
        label="设备型号"
        prop="model"
        :rules="[{ required: true, message: '请输入设备型号', trigger: 'blur' }]"
      >
        <v-input v-model="form.model" :width="385"/>
      </el-form-item>
      <el-form-item label="产地">
        <v-select v-model="form.origin" :options="originOps"></v-select>
      </el-form-item>
      <el-form-item label="制造商">
        <v-input v-model="form.manufactureCompany" :width="385"/>
      </el-form-item>
      <el-form-item label="出厂日期">
        <v-datepicker v-model="form.productionDate" type="date"/>
      </el-form-item>
      <el-form-item label="供应商">
        <v-input v-model="form.businessName" :width="385"/>
      </el-form-item>
      <el-form-item label="额定电压">
        <v-input v-model="form.electricVoltage"/>
      </el-form-item>
      <el-form-item label="额定电流">
        <v-input v-model="form.electricCurrent"/>
      </el-form-item>
      <el-form-item label="频率">
        <v-input v-model="form.frequency"/>
      </el-form-item>
      <el-form-item label="功率">
        <v-input v-model="form.capacityFactor"/>
      </el-form-item>
      <el-form-item label="其他技术参数">
        <v-textarea v-model="form.otherParams" :length="200"></v-textarea>
      </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import { saveDeviceParamsURL } from './../api'
import { originOps } from './../map'
export default {
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      originOps,
      submitUrl: saveDeviceParamsURL,
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      form: {
        installDate: '',
        handOverDate: '',
        startUserdDate: '',
        annualCheckDate: '',
        qualityAssuranceDate: '',
        registerCode: '',
        brand: '',
        factoryNumber: '',
        model: '',
        origin: undefined,
        manufactureCompany: '',
        productionDate: '',
        businessName: '',
        electricVoltage: '',
        electricCurrent: '',
        frequency: '',
        capacityFactor: '',
        otherParams: ''
      }
    }
  },
  mounted () {
    this.responseData && this.responseData.data && this.setInfo()
  },
  methods: {
    setInfo () {
      let { data } = this.responseData
      let keyArr = Object.keys(this.form)
      let newObj = {}
      keyArr.forEach(value => {
        if (value === 'categoryId') {
          newObj[value] = data.thridLevel
        } else {
          newObj[value] = data[value]
        }
      })
      newObj.origin = newObj.origin || undefined
      !newObj.otherParams && (newObj.otherParams = '')
      this.form = newObj
    },
    submitBefore (data) {
      if (this.publicParams.id) {
        data.id = this.publicParams.id
        return true
      } else {
        this.$alert('请先完善基础信息')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '基础信息'
        })
        return false
      }
    },
    submitSuccess (data) {
      this.$emit('contact', {
        method: 'setActiveLabel',
        data: '维保信息'
      })
      return false
    }
  }
}
</script>
