var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            staticClass: "form-container",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否维保",
                prop: "isMaint",
                rules: [
                  {
                    required: true,
                    message: "请输入是否维保",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.maintenanceOps },
                model: {
                  value: _vm.form.isMaint,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isMaint", $$v)
                  },
                  expression: "form.isMaint",
                },
              }),
            ],
            1
          ),
          _vm.form.isMaint !== 0
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "合同开始" } },
                    [
                      _c("v-datepicker", {
                        attrs: { type: "date" },
                        model: {
                          value: _vm.form.contractFrom,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "contractFrom", $$v)
                          },
                          expression: "form.contractFrom",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "合同结束" } },
                    [
                      _c("v-datepicker", {
                        attrs: { type: "date" },
                        model: {
                          value: _vm.form.contractTo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "contractTo", $$v)
                          },
                          expression: "form.contractTo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "维保负责人",
                        prop: "maintChargerUserId",
                        rules: [
                          {
                            required: true,
                            message: "请输入维保负责人",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "维保负责人",
                              subjoin: _vm.maintCompanyUserExtra,
                            },
                            model: {
                              value: _vm.form.maintChargerUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "maintChargerUserId", $$v)
                              },
                              expression: "form.maintChargerUserId",
                            },
                          },
                          "v-select2",
                          _vm.maintChargerUserParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "维保周期",
                        prop: "period",
                        rules: [
                          {
                            required: true,
                            message: "请输入维保周期",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.periodOps },
                        model: {
                          value: _vm.form.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "period", $$v)
                          },
                          expression: "form.period",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "维保提醒",
                        prop: "floatingDays",
                        rules: [
                          {
                            required: true,
                            message: "请输入维保提醒",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.floatingDaysOps },
                        model: {
                          value: _vm.form.floatingDays,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "floatingDays", $$v)
                          },
                          expression: "form.floatingDays",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "首次维保日期",
                        prop: "firstMaintDate",
                        rules: [
                          {
                            required: true,
                            message: "请输入首次维保日期",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c("v-datepicker", {
                            attrs: { type: "date" },
                            model: {
                              value: _vm.form.firstMaintDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "firstMaintDate", $$v)
                              },
                              expression: "form.firstMaintDate",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "right-tip" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "是否允许相册上传图片",
                                    prop: "allowAlbumUpload",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-color": "#409EFF",
                                    },
                                    model: {
                                      value: _vm.form.allowAlbumUpload,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "allowAlbumUpload",
                                          $$v
                                        )
                                      },
                                      expression: "form.allowAlbumUpload",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "维保单位" } },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "维保单位",
                              subjoin: _vm.maintCompanyUserExtra,
                            },
                            on: { onChange: _vm.maintCompanyChange },
                            model: {
                              value: _vm.form.maintCompanyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "maintCompanyId", $$v)
                              },
                              expression: "form.maintCompanyId",
                            },
                          },
                          "v-select2",
                          _vm.maintCompanyParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "维保单位分类" } },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.maintCompanyTypeOps },
                        model: {
                          value: _vm.form.maintCompanyType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "maintCompanyType", $$v)
                          },
                          expression: "form.maintCompanyType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "外包类型" } },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.outsourcingTypeOps },
                        model: {
                          value: _vm.form.outsourcingType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "outsourcingType", $$v)
                          },
                          expression: "form.outsourcingType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "联系人" } }, [
                    _vm._v(_vm._s(_vm.form.maintUser)),
                  ]),
                  _c("el-form-item", { attrs: { label: "联系电话" } }, [
                    _vm._v(_vm._s(_vm.form.maintUserPhone)),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "维保内容" } },
                    _vm._l(_vm.maintContent, function (item, index) {
                      return _c("div", { key: index }, [
                        _vm._v(_vm._s(index + 1) + "." + _vm._s(item)),
                      ])
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }